import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTHORIZED_RC, NOT_FOUND_RC } from '@core/constants/routes.constants';
import { AuthApi } from '@core/services/api/auth.api';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authApi: AuthApi,
    private router: Router
  ) {}

  canMatchUnauthorized(): boolean {
    if (!this.authApi.isAuthorized()) return true;
    this.router.navigate([AUTHORIZED_RC.root]);
    return false;
  }

  canMatchAuthorized(): boolean {
    if (this.authApi.isAuthorized()) return true;
    // this.router.navigate([AUTH_RC.root]);
    this.router.navigate([NOT_FOUND_RC.root]);
    return false;
  }
}
