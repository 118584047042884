import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AUTH_RC, AUTHORIZED_RC, NOT_FOUND_RC } from '@core/constants/routes.constants';
import { PreloadingStrategyService } from '@core/services/preloading-strategy.service';
import { AuthGuard } from '@core/guards/auth.guard';
import { IntakeSurveyGuard } from '@core/guards/intake-survey.guard';
import { DefaultRouteGuard } from '@core/guards/default-route.guard';

const routes: Routes = [
  {
    path: NOT_FOUND_RC.root,
    loadChildren: () => import('@features/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: AUTH_RC.root,
    loadChildren: () => import('@features/auth/auth.module').then((m) => m.AuthModule),
    canMatch: [() => inject(AuthGuard).canMatchUnauthorized()],
  },
  {
    path: AUTHORIZED_RC.root,
    loadChildren: () =>
      import('@features/authorized-content/authorized-content.module').then((m) => m.AuthorizedContentModule),
    canMatch: [() => inject(AuthGuard).canMatchAuthorized()],
    canActivate: [IntakeSurveyGuard, DefaultRouteGuard],
  },
  { path: '**', redirectTo: NOT_FOUND_RC.root, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadingStrategyService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
